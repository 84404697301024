/** @jsx jsx */
import { jsx, Container, Flex, Box, Image } from "theme-ui";
import { useState } from "react";
import { Button } from "../button";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "gatsby"
import Logo from "../logo";
import menuItems from "./header.data";
import logo from "assets/logos/wsl-logo.svg";
import Drawer from 'rc-drawer';
import burgerMenu from "assets/header/burger-menu.png";
import { IoMdClose, } from "react-icons/io";
import "rc-drawer/assets/index.css";
import { useIntl } from "gatsby-plugin-intl";

export default function Header({ className, color, scrollableLinks }) {
    
    const [isOpen, setIsOpen] = useState(false);
    
    const styles = {
        header: {
            color: "#fff",
            backgroundColor: "#111",
            fontFamily: "Barlow",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "18px",
            lineHeight: "22px",
            width: "100%",
            height: "80px",
            position: "fixed",
            top: 0,
            left: 0,
            transition: "all 0.4s ease",
            display: "flex",
            justifyContent: "center",

            "&.sticky": {
                backgroundColor: "#111",
                color: "#fff",
                boxShadow: "0 1px 2px rgba(0, 0, 0, 0.06)",
            },
        },
        headerBtn: {
            color: "#111",
            backgroundColor: "#FFE162",
            width: ["90px", "90px", "100px", "168px"],
            height: ["30px", "30px", "35px", "50px"],
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: ["12px", "12px", "12px", "18px"],
            lineHeight: "22px",
            letterSpacing: "0.15px",
            textDecoration: "none",
            borderRadius: "3px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        
        container: {
            display: "flex",
            alignItems: "center",
            "@media screen and (max-width: 1023px)": {
                justifyContent: "space-between",
                px: "20px",
                pr: "40px"
            },
        },
        nav: {
            mx: "auto",
            justifyContent: "space-around",
            width: "70%",
            "@media screen and (max-width: 1023px)": {
                display: "none",
            },
            navLink: {
                fontSize: 12,
                color: "#fff",
                fontWeight: "400",
                fontFamily: "Barlow",
                cursor: "pointer",
                lineHeight: [null, null, "14px", "22px"],
                letterSpacing: [null, null, "0.15px", "0.15px"],
                transition: "500ms",
                ":last-child": {
                    mr: "0",
                },
                "&:hover": {
                    color: "#FFE162",
                },
                "@media screen and (min-width: 1101px)": {
                    fontSize: 18
                },
                "@media screen and (max-width: 1100px)": {
                    fontSize: 15
                },
            },
        },
        burgerMenu: {
            display: "none",
            "@media screen and (max-width: 1023px)": {
                display: "block",
                transition: "transform 0.5s",
            },
        },
        separator: {
            display: "none",
            "@media screen and (max-width: 1023px)": {
                display: "block",
                ml: "14px",
                mr: "20px",
                height: "28px",
                width: "1px",
                border: "1px solid rgba(217, 217, 217, 0.2)",
            },
        },
        mobileMenu: {
            fontSize: 18,
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            pt: 60,
            px: 40,
            position: "relative"
        },
        logoMenu: {
            position: "absolute",
            bottom: 20,
            left: 40
        }
    };

    if (scrollableLinks === undefined) {
        scrollableLinks = true;
    }
    const intl = useIntl();
    
    return (
            <header sx={styles.header} className={className}>
                <Container sx={styles.container}>
                    { scrollableLinks && 
                        <ScrollLink to="home" smooth={true}>
                            <Logo width="130px" height="41px" image={logo} style="logoMax" />
                            <Logo width="90px" height="28px" image={logo} style="logoMin" />
                        </ScrollLink>
                    }
                    { ! scrollableLinks && 
                    <Link to={`/${intl.locale}/#home`}>
                        <Logo width="130px" height="41px" image={logo} style="logoMax" />
                        <Logo width="90px" height="28px" image={logo} style="logoMin" />
                    </Link>
                    }

                    <Flex as="nav" className="menubar" sx={styles.nav}>
                        {menuItems.map((item, i) =>
                            scrollableLinks && item.type === "scroll" ? (
                                <ScrollLink
                                    sx={styles.nav.navLink}
                                    to={item.path}
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    key={i}
                                >
                                    {item.label[intl.locale]}
                                </ScrollLink>
                            ) : (
                                <Link
                                    sx={styles.nav.navLink}
                                    to={`/${intl.locale}/${item.type == "scroll" ? "#" : ""}${item.path}`}
                                    key={i}
                                >
                                    {item.label[intl.locale]}
                                </Link>
                            )
                        )}                        
                    </Flex>

                    <Box as="div" style={{ display: "flex", alignItems: "center" }}>                        
                        <Button 
                            label="PLAY NOW" 
                            target="_blank" 
                            to="https://worldsuperleague.noku.io" 
                            variant="lg"
                            color="yellow"
                        />
                        <Box as="div" sx={styles.separator}></Box>
                        <Drawer 
                            width="300px" 
                            open={isOpen}
                            handler={
                                <div className="drawer-handle"
                                    onClick={() => setIsOpen(!isOpen)}>
                                    <Box 
                                        as="div"
                                        sx={styles.burgerMenu}
                                    >
                                        <Image src={burgerMenu} />
                                    </Box>
                                </div>
                            }
                            placement="right">
                                <Box sx={styles.mobileMenu}>
                                    <IoMdClose 
                                        size={30}
                                        onClick={() => setIsOpen(false)}
                                        sx={{
                                            position: "absolute",
                                            right: 20,
                                            top: 20
                                        }} />
                                    {menuItems.map((item, i) =>
                            scrollableLinks && item.type === "scroll" ? (
                                <ScrollLink
                                    sx={styles.nav.navLink}
                                    to={item.path}
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    key={i}
                                >
                                    {item.label[intl.locale]}
                                </ScrollLink>
                            ) : (
                                <Link
                                    sx={styles.nav.navLink}
                                    to={`/${intl.locale}/${item.type == "scroll" ? "#" : ""}${item.path}`}
                                    key={i}
                                >
                                    {item.label[intl.locale]}
                                </Link>
                            )
                        )}                                                                    
                                </Box>
                                <Image width="90px" height="28px" src={logo} style={styles.logoMenu} />
                        </Drawer>
                    </Box>
                </Container>
            </header>
    );
}
