import React from "react";
import { Link } from "theme-ui";

export function Button({ to, label, target, variant, color }) {
    const styles = {
        btn: {
            flexDirection: "column",
            display: "flex",
            width: "fit-content",
            justifyContent: "center",
            border: ["1px solid #fff", "1px solid #fff", "2px solid #fff", "2px solid #fff"],
            borderRadius: "3px",
            cursor: "pointer",
            color: "#fff",
            backgroundColor: "transparent",
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: ["12px", "12px", "12px", "18px"],
            lineHeight: "22px",
            textAlign: "center",
            letterSpacing: "0.15px",
            textDecoration: "none",

            "&:hover": {
                borderColor: "transparent"
            },

            "& span": {
                padding: "8px 16px"
            }
        },
        dark: {
            border: ["1px solid #666", "2px solid #666", "2px solid #666"],
            "& span": {
                color: "#666",
                padding: "12px 24px"
            }
        },
        lg: {
            fontSize: ["12px", "15px", "15px", "18px"],
        }
    }

    function mergeStyle() {
        let css = styles.btn;
        if(variant && styles[variant]) {
            css = {...css, ...styles[variant] };
        }
        if(color && styles[color]) {
            css = {...css, ...styles[color] };
        }
        return css;
    }

    return (
        <Link className={`button--outline button--outline-${variant} button--outline-${color}`} href={to} target={target} sx={mergeStyle()}>
            <div className="button__bg"></div><span>{label}</span>
        </Link>
    );
}
