const headerData = [
    {
        label: { 
            "en": "The Game",
            "it": "Il Gioco",
        },
        path: "theGame",
        type: "scroll"
    },
    {   
        label: { 
            "en": "Cards",
            "it": "Le Card",
        },
        path: "cardsGame",
        type: "scroll"
    },
    {
        label: { 
            "en": "Buy Cards",
            "it": "Acquista Card"
        },
        type: "scroll",
        path: "buyPack",    
    },
    {
        label: { 
            "en": "How to play",
            "it": "Come giocare",
        },
        path: "how-to-play",
        type: "normal"
    },
    {
        label: { 
            "en": "Roadmap",
            "it": "Roadmap"
        },
        type: "scroll",
        path: "roadMap",
    },
];
export default headerData
