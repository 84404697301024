/** @jsx jsx */
import { jsx, Image } from 'theme-ui';
import { Link } from 'components/link';

export default function Logo({ width, heigth, image, style }) {
  const styles = {
    logoMax: {
      variant: 'links.logo',
      display: 'flex',
      alignItems: 'center',
      "@media screen and (max-width: 1679px)": {
        display: "none",
      },
    },
    logoMin: {
      display: "none",
      "@media screen and (max-width: 1679px)": {
        variant: 'links.logo',
        display: 'flex',
        alignItems: 'center',
      },
    },
  }
  return (
    <Link
      path="/"
      sx={styles[style]}
    >
      <Image src={image} width={width} heigth={heigth} alt="logo" />
    </Link>
  );
}
